<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <h1>Employees</h1>
        <bread-crumbs :breadcrumbs="breadcrumbs" />
      </div>
      <div class="header-aside">
        <search />
      </div>
    </div>
    <tabs :tabs="tabs">
      <template #individuals><employee-tab /></template>
      <!-- <template #teams><team-tab /></template> -->
    </tabs>
  </fragment>
</template>

<script>
import EmployeeTab from "@/modules/admin/employees/individuals/EmployeeTab";
// import TeamTab from "@/modules/admin/employees/teams/TeamTab";
import { mapGetters, mapActions } from "vuex";
import { Tabs, Search, BreadCrumbs } from "@/components";
import { Fragment } from "vue-fragment";

export default {
  name: "EmployeesView",
  components: { 
    BreadCrumbs, 
    Tabs, 
    // TeamTab, 
    EmployeeTab, 
    Fragment, 
    Search 
  },
  data: () => {
    return {
      tabs: [
        { key: "individuals", label: "Individuals" },
        // { key: "teams", label: "Teams" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isFlyoutShowing"]),
    breadcrumbs() {
      return [{ name: "Employees" }];
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
  },
};
</script>
